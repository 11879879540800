
import React from "react"
export const PrevIcon = () => (
  <svg id="Group_635" data-name="Group 635" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="81" height="81" viewBox="0 0 81 81">
  <defs>
    <clipPath id="clip-path">
      <rect id="_20_Background_Mask_" data-name="20 (Background/Mask)" width="27" height="27"/>
    </clipPath>
  </defs>
  <g id="Ellipse_7" data-name="Ellipse 7" fill="none" stroke="#000" stroke-width="1">
    <circle cx="40.5" cy="40.5" r="40.5" stroke="none"/>
    <circle cx="40.5" cy="40.5" r="40" fill="none"/>
  </g>
  <g id="Group_631" data-name="Group 631" transform="translate(15.75 27)">
    <g id="_20" data-name="20" clip-path="url(#clip-path)">
      <g id="Group" transform="translate(4.114 4.023)">
        <path id="Vector" d="M16.972,9.594,19.15,1.536A1.146,1.146,0,0,0,17.6.119L.648,8.514a1.235,1.235,0,0,0,0,2.174L17.6,19.082a1.152,1.152,0,0,0,1.549-1.429Z" transform="translate(0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.2" stroke-dasharray="0 0"/>
        <path id="Vector-2" data-name="Vector" d="M7.2,0H0" transform="translate(10.798 9.598)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.2" stroke-dasharray="0 0"/>
      </g>
    </g>
    <line id="Line_4" data-name="Line 4" x2="27" transform="translate(22.5 13.5)" fill="none" stroke="#000" stroke-width="1"/>
  </g>
</svg>
)