import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
// import Fade from 'react-reveal/Fade';
import { Section } from "@app/components/Section";
import { ProjectSlider } from "@app/components/ProjectSlider";
import { Fade } from "@app/components/animations/Fade";

const IndexPage = ({ data }: any) => {
  console.log('data', data)
  return (
    <Layout>
      <Section
        id='introduction'
        className="introduction"
        disableAnimate
        vCentered
        visualScroll
        scrollTo="crystal"
      >
        <Fade bottom>
          <div className="hi-text text-white">hi, we are</div>
        </Fade>
        <h1 className="logo-text">crystal.</h1>
      </Section>
      <Section
        id="crystal"
        className="first"
        index={"1"}
        heading={<Fade>What we do</Fade>}
      >
        <Fade as="p" className="text-container">Crystal is a digital consulting, design, and engineering studio trusted by leading enterprise 
          organizations and fast-growing startups across Asia Pacific. We advocate cutting-edge technologies 
          and craft powerful and practical experiences that resonate.</Fade>
      </Section>
      <Section
        id="projects"
        index="2"
        heading={<Fade>Projects</Fade>}
      >
        <ProjectSlider
          projects={data.projects}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    projects: allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {hide: {eq: false}}}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          type
          path
          thumb_image {
            childImageSharp {
              gatsbyImageData(width: 500, height: 337, quality: 100)
            }
          }
        }
        id
        slug
      }
    }
  }
`
// fixed(width: 445, height: 300) {
//   ...GatsbyImageSharpFixed
// }

export default IndexPage
