import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link, graphql, navigate } from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import './ProjectSlider.scss';
import { NextIcon } from './NextIcon';
import { PrevIcon } from './PrevIcon';
import { Fade } from '../animations/Fade';


function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <NextIcon/>
    </div>
  );
}


function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <PrevIcon/>
    </div>
  );
}

export type ProjectSilderProps = {
  projects: any
}

export const ProjectSlider = (props: any) => {
  const [dragging, setDragging] = useState(false)

  const handleBeforeChange = useCallback(() => {
    setDragging(true)
  }, [setDragging])

  const handleAfterChange = useCallback(() => {
    setDragging(false)
  }, [setDragging])

  const handleOnItemClick = useCallback(
      (e, path) => {
        e.preventDefault();
        if (dragging) {
          e.stopPropagation()
          setDragging(false)
        } else {
          navigate(path);
        }
      },
      [dragging]
  ) 

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 3,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    rows: 1,
    // draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='project-silder-container'>
      <Fade>
        <Slider
          {...settings}
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
          className="project-slider"
        >
          {props.projects.nodes.map((item: any) => {
            const image = getImage(item.frontmatter.thumb_image);
            return (
              <div className='project-item'>
                <a
                  onClick={(e) => handleOnItemClick(e, item.frontmatter.path || `/projects/${item.slug}`)}
                  href={item.frontmatter.path || `/projects/${item.slug}`}
                >
                  <GatsbyImage
                    image={image}
                  />
                </a>
                <div className='project-item--name'>
                  <Link
                    to={item.frontmatter.path || `/projects/${item.slug}`}
                  >
                  {item.frontmatter.title}
                  </Link>
                </div>
                <p className='project-item--type mb-0'>
                  {item.frontmatter.type}
                </p>
              </div>
            )
          })}
        </Slider>
      </Fade>
      <Fade
        className="btn-view-all"
      >
        <Link to='/projects' className='btn btn-primary'>
          View all projects
        </Link>
      </Fade>
    </div>
  );
}